
interface PlanModelLimits {
    [plan: string]: {
      [modelName: string]: number;
    };
  }
  
  export const planModelLimits: PlanModelLimits = {
    // Basic: {
    //   "gpt-4o-mini-2024-07-18": 500, // Example limit for Basic plan
    //   "gpt-4o-2024-08-06": 800, // Example limit for Basic plan
    // },
    None:{
      "gpt-4o-mini-2024-07-18": 50, // Example limit for Standard plan
    },
    Standard: {
      "gpt-4o-mini-2024-07-18": 500, // Example limit for Standard plan
      "gpt-4o-2024-08-06": 250, // Example limit for Standard plan
    },
    Advanced: {
      "gpt-4o-mini-2024-07-18": 750, // Example limit for Advanced plan
      "gpt-4o-2024-08-06": 500, // Example limit for Advanced plan
    },
  };
  