import React, {
    useState,
    useEffect,
    useRef,
    ChangeEvent,
    KeyboardEvent,
    Fragment
} from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './config/firebase';
import { User } from 'firebase/auth';
import {
    doc,
    getDoc,
    updateDoc,
    setDoc,
    increment,
    collection,
    query,
    where,
    getDocs,
    addDoc
} from 'firebase/firestore';

// Material UI and Headless UI
import { Snackbar, Alert, Button, Tooltip } from '@mui/material';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild
} from '@headlessui/react';

// Icons
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { IconMessage, IconEdit, IconX, IconTag } from '@tabler/icons-react';
import { ArrowUpCircleIcon } from '@heroicons/react/24/solid';

// React Markdown & related plugins for math/code highlighting
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypePrism from 'rehype-prism-plus';
import 'katex/dist/katex.min.css';
import 'prismjs/themes/prism-okaidia.css';
import yaml from 'js-yaml';

// OpenAI and prompt
import OpenAI from 'openai';
import { generateQuestionPrompt } from './Prompts';

// Local assets, utils, and constants
import Navbar from './navbar';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import loadingGif from './assets/loading.gif';
import Pre from './copy';
import { planModelLimits } from './modelLimits';
import saveActivity from './utils/saveActivity';

// For HTML rendering (similar to the old version)
import DOMPurify from 'dompurify';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import './App.css';

import { Menu } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon, CheckIcon, UserCircleIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { signOut } from 'firebase/auth';
import { IconHelp, IconListNumbers, IconLogout, IconSettings, IconPlus } from '@tabler/icons-react';

import { ChevronRightIcon } from '@heroicons/react/24/solid';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';




// ------------------------------------------------------------
// Component for rendering HTML safely with math rendering
interface RenderHTMLContentProps {
    content: string;
}

const renderMath = (htmlContent: string) => {
    const mathPattern = /\\\[(.*?)\\\]|\\\((.*?)\\\)/g;
    return htmlContent.replace(mathPattern, (match, displayMath, inlineMath) => {
        try {
            const renderedMath = katex.renderToString(displayMath || inlineMath, {
                throwOnError: false,
                displayMode: !!displayMath
            });
            return renderedMath;
        } catch (error) {
            console.error('Failed to render math:', error);
            return match;
        }
    });
};

const RenderHTMLContent: React.FC<RenderHTMLContentProps> = ({ content }) => {
    const sanitizedContent = DOMPurify.sanitize(renderMath(content));
    return (
        <div
            className="custom-html-content"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        />
    );
};
// ------------------------------------------------------------

const ChatGenerateQuestion: React.FC = () => {
    const navigate = useNavigate();

    /*******************************************************************
     * STATE - AUTH, SUBSCRIPTION, USAGE
     *******************************************************************/
    const [user, setUser] = useState<User | null>(null);
    const [hasAccess, setHasAccess] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('Inactive');
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState(false);
    const [plan, setPlan] = useState('None');
    const [usageStats, setUsageStats] = useState<{ [key: string]: number }>({});

    // For limiting total number of user questions
    const [numQuestions, setNumQuestions] = useState(0);
    const [maxQuestionsDialogOpen, setMaxQuestionsDialogOpen] = useState(false);

    // For usage-limit-exceeded dialog
    const [limitDialogOpen, setLimitDialogOpen] = useState(false);
    const [exceededModel, setExceededModel] = useState<string | null>(null);
    const [currentUsage, setCurrentUsage] = useState<number>(0);
    const [modelLimit, setModelLimit] = useState<number>(0);

    // For generic errors shown by Snackbar or Dialog
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState('');

    // Chat states – default assistant message now appears immediately.
    const [messages, setMessages] = useState<any[]>([
        {
            role: 'assistant',
            content:
                'Hello! I am your interview question generation assistant. Please describe the question you would like to practice and I will generate a real interview question on the panel to the right.'
        }
    ]);
    const [inputText, setInputText] = useState('');
    const [loading, setLoading] = useState(false);
    const [prelimLoading, setPrelimLoading] = useState(false);

    const [loadingText, setLoadingText] = useState('');

    // Generated question states
    const [genQuestionBody, setGenQuestionBody] = useState('');
    const [genQuestionTitle, setGenQuestionTitle] = useState('');
    const [genTags, setGenTags] = useState<string[]>([]);
    const [genQuestionAnswer, setGenQuestionAnswer] = useState('');
    const [genAnswerType, setGenAnswerType] = useState('');

    // Indicates that the complete generation (all three steps) is done
    const [hasLoadedGenQuestion, setHasLoadedGenQuestion] = useState(false);
    const [startedLoadingQuestion, setStartedLoadingQuestion] = useState(false);


    // A ref for scrolling chat messages
    const messagesContainerRef = useRef<HTMLDivElement | null>(null);

    // For highlighting the chat container on focus
    const [isActive, setIsActive] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);


    // State for dialog visibility
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modelName, setModelName] = useState<string>('gpt-4o-mini-2024-07-18');

    // Open model selector dialog
    const openModelDialog = async () => {
        if (user) {
            // Force a server read to get the most up-to-date stats
            await fetchUsageStats(user);
        }
        setIsDialogOpen(true);
    };



    // Close dialog
    const closeDialog = () => setIsDialogOpen(false);



    // Model options
    const notificationMethods = [
        {
            id: 'gpt-4o-mini-2024-07-18',
            title: 'GPT-4o mini',
            subtitle: 'Cost-efficient model based on gpt-4o-mini-2024-07-18.',
        },
        {
            id: 'gpt-4o-2024-08-06',
            title: 'GPT-4o',
            subtitle: 'Most advanced model based on gpt-4o-2024-08-06.',
        },
    ];


    function classNames(...classes: (string | undefined)[]) {
        return classes.filter(Boolean).join(' ');
    }

    const filteredMethods =
        plan === 'None'
            ? notificationMethods.filter((method) => method.id === 'gpt-4o-mini-2024-07-18')
            : notificationMethods;

    const handleModelChange = (id: string) => {
        if (user) {
            const userId = user.uid;
            setModelName(id);
            localStorage.setItem(`hinterviewGpt_modelName_${userId}`, id);
        }
    };
    /*******************************************************************
     * EFFECTS
     *******************************************************************/
    useEffect(() => {
        if (user) {
            fetchUsageStats(user);
        }
    }, [user]);


    // Firebase Auth check, subscription checks, question count, etc.
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                fetchUsageStats(authUser);
            } else {
                setUser(null);
                navigate('/auth');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            if (!user) return;
            const subsRef = collection(db, 'customers', user.uid, 'subscriptions');
            const activeSubsQuery = query(subsRef, where('status', 'in', ['trialing', 'active']));
            const snapshot = await getDocs(activeSubsQuery);
            const subs: any[] = [];
            snapshot.forEach(doc => subs.push(doc.data()));

            if (subs.length > 0) {
                const activeSubscription = subs[0];
                setSubscriptionStatus(activeSubscription.status);
                if (activeSubscription.cancel_at_period_end) setCancelAtNextPeriod(true);
                const planNickname = activeSubscription.items[0]?.price?.product?.name || 'None';
                setPlan(planNickname);
                setHasAccess(true);
            } else {
                const signUpDate = new Date(user.metadata.creationTime!);
                const currentDate = new Date();
                const daysSinceSignUp = Math.floor(
                    (currentDate.getTime() - signUpDate.getTime()) / (1000 * 3600 * 24)
                );
                if (daysSinceSignUp < 7) {
                    setTrialRemaining(7 - daysSinceSignUp);
                    setHasAccess(true);
                    setPlan('None');
                } else {
                    setHasAccess(false);
                    setSubscriptionStatus('Inactive');
                }
            }
        };
        if (user) fetchSubscriptions();
    }, [user]);

    useEffect(() => {
        const fetchUserQuestionCount = async () => {
            if (!user) return;
            try {
                const userQuestionListRef = doc(db, 'userQuestionList', user.uid);
                const userQuestionListSnap = await getDoc(userQuestionListRef);
                if (userQuestionListSnap.exists()) {
                    const data = userQuestionListSnap.data();
                    const questionsArr = data.questions || [];
                    setNumQuestions(questionsArr.length);
                }
            } catch (err) {
                console.error('Error fetching userQuestionList:', err);
            }
        };
        fetchUserQuestionCount();
    }, [user]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (loading) {
            interval = setInterval(() => {
                setLoadingText(prev => (prev.length < 5 ? prev + '.' : ''));
            }, 250);
        } else {
            setLoadingText('');
        }
        return () => clearInterval(interval);
    }, [loading]);

    const logOut = async () => {
        try {
            await signOut(auth);
            navigate('/auth');
            setUser(null);
        } catch (err: any) {
            console.error(err);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsActive(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);

    /*******************************************************************
     * FUNCTIONS - FETCH USAGE, ETC.
     *******************************************************************/
    const fetchUsageStats = async (authUser: User): Promise<{ [key: string]: number }> => {
        try {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const userUsageRef = doc(db, 'userUsage', `${authUser.uid}_${year}_${month}`);
            const userUsageSnap = await getDoc(userUsageRef);
            let models: { [key: string]: number } = {};
            if (userUsageSnap.exists()) {
                const data = userUsageSnap.data();
                models = data.models || {};
                setUsageStats(models);
            } else {
                setUsageStats({});
            }
            return models;
        } catch (error) {
            console.error('Error fetching usage stats:', error);
            return {};
        }
    };



    /*******************************************************************
     * FUNCTIONS - CHAT & QUESTION GENERATION
     *******************************************************************/
    const handleInputTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputText(e.target.value);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const showErrorDialog = (title: string, message: string) => {
        setErrorDialogTitle(title);
        setErrorMessage(message);
        setErrorDialogOpen(true);
    };

    const showSnackbarError = (msg: string) => {
        setErrorMessage(msg);
        setErrorSnackbarOpen(true);
    };

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const checkInputIntent = async (chatHistory: any[]): Promise<boolean> => {
        try {
            // Initialize OpenAI (using your key)
            const openai = new OpenAI({
                apiKey: process.env.REACT_APP_OPENAI_API_KEY,
                dangerouslyAllowBrowser: true
            });

            // Combine the chat history into a single string.
            // You might adjust the formatting as needed.
            const conversationString = chatHistory
                .map((msg) => `${msg.role}: ${msg.content}`)
                .join("\n");

            // Construct the validation prompt using the entire conversation history.
            const validationPrompt = `

            You are an AI agent that is the intial step in a system that ultimately generates interview questions.  Your job is to determine if the intent of the user is to 
            generate an interview question/edit an interview question that has already been generated OR if the user has some other intent.  For example,
            some other intent may be to say hello, ask for help or maybe the user is just writing gibberish - essentially some intent other than creating
            or editing an interview question.  

            A user can demonstrate their intent to generate or edit an existing question by specifying a job role or industry or difficulty level or even technical or non-technical
            topics that would lend themselfs to generating an interview questions.  They may even mention leetcode or hackerrank which are relate to software engineering interview
            questions.

            You will be provided with a conversation history.  If you determine that the last user message is intended to generate or edit and interview question, 
            respond with exactly "valid".  If you determine that the last user message has some other intent, respond with exactly "invalid".
      
            Conversation history:
            ${conversationString}
      `;

            // Send the prompt to the OpenAI API
            const response = await openai.chat.completions.create({
                model: 'gpt-4o-mini-2024-07-18',
                messages: [{ role: 'system', content: validationPrompt }],
                temperature: 0.0,
                stream: false
            });

            // Extract, trim, and lower-case the answer
            const answerContent = response.choices[0]?.message?.content;
            const answer = answerContent ? answerContent.trim().toLowerCase() : '';
            console.log('Conversation String:', conversationString);
            console.log('Intent Answer:', answer);

            // Return true only if the answer is exactly "valid"
            return answer === 'valid';
        } catch (error) {
            console.error('Error determining intent:', error);
            // Treat errors as an invalid conversation
            return false;
        }
    };

    const followUpQuestion = async (chatHistory: any[]): Promise<String> => {
        try {
            // Initialize OpenAI (using your key)
            const openai = new OpenAI({
                apiKey: process.env.REACT_APP_OPENAI_API_KEY,
                dangerouslyAllowBrowser: true
            });

            // Combine the chat history into a single string.
            // You might adjust the formatting as needed.
            const conversationString = chatHistory
                .map((msg) => `${msg.role}: ${msg.content}`)
                .join("\n");

            // Construct the validation prompt using the entire conversation history.
            const validationPrompt = `

            You are a an AI agent in a system that ultimately generates interview questions. The user has just sent a message that was deemed to not have the intent to generate or edit an existing question.
            Your job is to follow up with the user and respond to them as necessary.   I will provide you some details of the system that may or may not be relevant to the user's message.  
            You exist as part of a system called HinterviewGPT.  HinterviewGPT is
            a platform for generating interview questions and practicing them with the help of an AI tutor.  The part of the system that is currently being used is
            a chat ui that allows the  user to generate relevant and realistic interview questions that they can then practice.  Once the user has given the chat
            enough information to generate a question, the question will be generated on the panel to the right of the chat ui.  If the user is happy with the 
            question that is generated they can click the green "Practice" button and start practicing it with the help of the AI tutor. If they are not happy with the
            question, they can continue the chat to make edits to the question. Based on the following conversation history, please answer the user's message as best as you can. 
      
            Given the above description of the system, please answer the user's message as best as you can.  The user may be asking a question that you can answer.  Or possibly the 
            user sent a message that is difficult to understand and you may need to ask the user to clarify their message.  The user may also be thanking you or criticizing you, in
            this case simply thank them for their feedback.  Below is the full conversation history.  Please respond to the user's message as best as you can.
            Conversation history:
            ${conversationString}
      `;

            // Send the prompt to the OpenAI API
            const response = await openai.chat.completions.create({
                model: 'gpt-4o-mini-2024-07-18',
                messages: [{ role: 'system', content: validationPrompt }],
                temperature: 0.0,
                stream: false
            });

            // Extract, trim, and lower-case the answer
            const answerContent = response.choices[0]?.message?.content || '';

            console.log('Conversation String:', conversationString);
            console.log('Followup Answer:', answerContent);

            // Return true only if the answer is exactly "valid"
            return answerContent;
        } catch (error) {
            console.error('Error from followup agent:', error);
            // Treat errors as an invalid conversation
            return "Sorry an error occurred. Please try again.";
        }
    };


    // Clear conversation to just the default assistant message
    const handleClearConversation = () => {
        setMessages([
            {
                role: 'assistant',
                content:
                    'Hello! I am your interview question generation assistant. Please describe the question you would like to practice and I will generate a real interview question on the panel to the right.'
            }
        ]);
    };

    const handleGoBack = () => {
        navigate('/add');
    };


    /*******************************************************************
     * FUNCTION - HANDLE GENERATION (THREE STEPS)
     *******************************************************************/
    const handleSendMessage = async () => {
        if (!user) {
            showErrorDialog('Authentication Error', 'User not authenticated.');
            return;
        }

        if (numQuestions >= 182) {
            setMaxQuestionsDialogOpen(true);
            return;
        }

        if (!hasAccess) {
            showErrorDialog(
                'Subscription Required',
                'Your free trial may have expired or you do not have an active subscription. Please upgrade to continue.'
            );
            return;
        }

        // Reset generated question states until full generation completes.
        //setHasLoadedGenQuestion(false);
        // setGenQuestionBody('');
        // setGenQuestionTitle('');
        // setGenTags([]);
        // setGenQuestionAnswer('');
        // setGenAnswerType('');

        const userId = user.uid;
        const localApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
        const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
        const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`) || '';

        const apiKey =
            plan === 'Advanced' || plan === 'Standard' || plan === 'None'
                ? process.env.REACT_APP_OPENAI_API_KEY
                : localApiKey;

        if (!apiKey) {
            showErrorDialog('Missing API Key', 'No API key found. Please configure your OpenAI API key.');
            return;
        }
        if (!isApiKeyActivated && !(plan === 'Advanced' || plan === 'Standard' || plan === 'None')) {
            showErrorDialog('OpenAI API Key Error', 'Your personal API key is not activated.');
            return;
        }
        if (!latestModelName) {
            showErrorDialog('Model Error', 'No model was selected. Please pick a model in the navbar.');
            return;
        }

        // Check plan limits
        const planLimits = planModelLimits[plan] || {};
        const allowedLimit = planLimits[latestModelName] || 0;
        if (!allowedLimit) {
            showErrorDialog('Model Limit Error', 'Model limit is 0 on your current plan.');
            return;
        }
        const usageData = await fetchUsageStats(user);
        const currentUsageForModel = usageData[latestModelName] || 0;
        if (currentUsageForModel >= allowedLimit) {
            setExceededModel(latestModelName);
            setCurrentUsage(currentUsageForModel);
            setModelLimit(allowedLimit);
            setLimitDialogOpen(true);
            return;
        }

        setUsageStats(usageData);

        if (!inputText.trim()) return;

        try {


            const userMessage = { role: 'user', content: inputText };
            setMessages(prev => [...prev, userMessage]);
            setInputText('');

            // Prepare the conversation history for validation by including the new user message.
            const conversationForValidation = [...messages, userMessage];

            setPrelimLoading(true);
            // Validate the entire chat history
            const isIntentGenQuestion = await checkInputIntent(conversationForValidation);
            console.log('isIntentGenQuestion:', isIntentGenQuestion);

            if (!isIntentGenQuestion) {

                // If the intent is not to generate a question, just respond to it and don't generate a question.
                const followUpResponse = await followUpQuestion(conversationForValidation);
                console.log('followUpResponse:', followUpResponse);

                setPrelimLoading(false);
                // Instead of showing an error dialog, append an assistant message in the chat.
                setMessages(prev => [
                    ...prev,
                    {
                        role: 'assistant',
                        content: followUpResponse
                    }
                ]);

                return;
            }

            setGenQuestionBody('');
            setGenQuestionTitle('');
            setGenTags([]);
            setGenQuestionAnswer('');
            setGenAnswerType('');

            setStartedLoadingQuestion(true);


            setPrelimLoading(false);

            setLoading(true);

            // Build conversation history:
            // - Start with the system prompt.
            // - Then include all previous messages.
            // - If a generated question already exists, include it as an assistant message.
            // - Finally, include the new user message.
            const conversationHistory = [
                { role: 'system', content: generateQuestionPrompt },
                // Filter out any messages that should not be included in the prompt.
                ...messages.filter(message => !message.skipForPrompt),
                ...(genQuestionBody
                    ? [
                        {
                            role: 'assistant',
                            content: `Generated Question:
            Title: ${genQuestionTitle}
            Tags: ${genTags.join(', ')}
            ${genQuestionBody}`
                        }
                    ]
                    : []),
                userMessage
            ];


            console.log('Conversation History:', conversationHistory);

            const openai = new OpenAI({
                apiKey,
                dangerouslyAllowBrowser: true
            });

            // STEP 1: Generate question body using full conversation history.
            const responseBody = await openai.chat.completions.create({
                model: latestModelName,
                messages: conversationHistory,
                temperature: 0.7,
                stream: false
            });
            let questionBody = responseBody.choices[0]?.message?.content || '';
            const strippedQuestionBody = questionBody.replace(/```[a-zA-Z]*\s*|```/g, '');
            // (Do not update right pane yet.)

            console.log('Question Body:', questionBody);

            // STEP 2: Generate title and metadata (YAML)
            const yamlResponse = await openai.chat.completions.create({
                model: 'gpt-4o-mini-2024-07-18',
                messages: [
                    {
                        role: 'system',
                        content:
                            `You are an expert in creating job interview questions.
      Given the following job interview question, generate a valid yaml string that includes a short but descriptive title (no more than 10 words), a list of at most 3 informative tags, and answerType which you will set to "Code" if the answer should be written in code or "Text" if the question should be written in text. Your response should be a valid yaml string. Here is an example (do not include markdown formatting e.g. \`\`\`yaml, it should have the format exactly as follows):
      
      interview_question:
        title: >
          title of the question
        tags:
          - tag 1
          - tag 2
          - tag 3
        answerType: "Code" or "Text"`
                    },
                    { role: 'system', content: strippedQuestionBody }
                ],
                temperature: 1.0,
                stream: false
            });
            const yamlContent = yamlResponse.choices[0]?.message?.content || '{}';
            let parsedData: any;
            try {
                parsedData = yaml.load(yamlContent);
            } catch (e) {
                console.error('Error parsing YAML:', e);
                console.log('YAML content:', yamlContent);
                parsedData = { interview_question: { title: '', tags: [], answerType: 'Text' } };
            }
            const generatedTitle = parsedData.interview_question?.title || '';
            const generatedTags = parsedData.interview_question?.tags || [];
            const generatedAnswerType = parsedData.interview_question?.answerType || '';
            setLoading(false);


            console.log('Generated Title:', generatedTitle);
            // Update all generated states at once
            setGenQuestionBody(strippedQuestionBody);
            setGenQuestionTitle(generatedTitle);
            setGenTags(generatedTags);
            setGenAnswerType(generatedAnswerType);

            // Mark that generation is complete so the right pane renders everything.
            setHasLoadedGenQuestion(true);

            // Update Firebase usage
            const now = new Date();
            const yearNow = now.getFullYear();
            const monthNow = now.getMonth() + 1;
            const userUsageRef = doc(db, 'userUsage', `${userId}_${yearNow}_${monthNow}`);
            console.log('lastestModelName', latestModelName);
            await updateDoc(userUsageRef, {
                [`models.${latestModelName}`]: increment(1)
            }).catch(async (error: any) => {
                if (error.code === 'not-found') {
                    await setDoc(userUsageRef, { year: yearNow, month: monthNow, models: { [latestModelName]: 1 } });
                } else {
                    console.error('Error updating usage:', error);
                }
            });


            // Append final assistant message indicating generation complete.
            // Trim whitespace off the title.
            const trimmedTitle = generatedTitle.trim();


            // Create the tailored message.
            const tailoredMessage = {
                role: 'assistant',
                content: `Your interview question "${trimmedTitle}" is ready! If you would like to make a change to the question, let's continue the chat, otherwise click the "Practice" button on the right to practice it.`,
                skipForPrompt: true  // Mark this message as UI-only.
            };

            setMessages(prev => [...prev, tailoredMessage]);
            setInputText('');

            scrollToBottom();
        } catch (error: any) {
            console.error('Error generating question:', error);
            setLoading(false);
            setPrelimLoading(false);
            showSnackbarError(
                error instanceof Error ? error.message : 'An error occurred while generating the question.'
            );
        }
    };

    /*******************************************************************
     * NEW FUNCTION - SAVE GENERATED QUESTION (handleGenSubmit)
     * This mimics the "Add" button behavior from the old version.
     *******************************************************************/
    const handleGenSubmit = async () => {
        if (!user) {
            showSnackbarError('User not authenticated.');
            return;
        }
        try {
            // 1. Insert document in userQuestions collection.
            const userQuestionsRef = collection(db, 'userQuestions');
            const newQuestionRef = await addDoc(userQuestionsRef, {
                user: user.uid,
                title: genQuestionTitle,
                tags: genTags,
                answerType: genAnswerType,
                body: genQuestionBody,
                answer: genQuestionAnswer,
                method: 'generated',
                createdAt: new Date().toLocaleString()
            });

            // 2. Update userQuestionList document.
            const userQuestionListRef = doc(db, 'userQuestionList', user.uid);
            const userQuestionListSnap = await getDoc(userQuestionListRef);
            if (userQuestionListSnap.exists()) {
                const data = userQuestionListSnap.data();
                const oldQs = data.questions || [];
                await updateDoc(userQuestionListRef, {
                    questions: [
                        ...oldQs,
                        {
                            qRef: newQuestionRef.id,
                            title: genQuestionTitle,
                            tags: genTags,
                            answerType: genAnswerType
                        }
                    ]
                });
            } else {
                await setDoc(userQuestionListRef, {
                    questions: [
                        {
                            qRef: newQuestionRef.id,
                            title: genQuestionTitle,
                            tags: genTags,
                            answerType: genAnswerType
                        }
                    ]
                });
            }

            const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${newQuestionRef.id}`);

            const userAttemptData = {
                userID: user.uid,
                questionID: newQuestionRef.id,
                code: []
            };
            try {

                await setDoc(userAttemptDocRef, userAttemptData);

            } catch (error) {
                console.error('Error saving code:', error);
                // Handle error, maybe show a notification to the user
            }

            // Navigate to the question detail page.
            navigate(`/question/${newQuestionRef.id}`);
            await saveActivity(user.uid, 'addCount');
        } catch (error) {
            console.error('Error saving question:', error);
            showSnackbarError('Failed to save question.');
        }
    };

    /*******************************************************************
     * UTILS FOR RENDERING MESSAGES
     *******************************************************************/
    const preprocessLaTeX = (content: string) => {

        const blockProcessed = content.replace(/\\\[(.*?)\\\]/gs, (_, eq) => `$$${eq}$$`);
        const inlineProcessed = blockProcessed.replace(/\\\((.*?)\\\)/gs, (_, eq) => `$${eq}$`);
        return inlineProcessed;
    };

    const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
        return (
            <ReactMarkdown
                children={preprocessLaTeX(markdownContent)}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex, rehypePrism]}
                components={{ pre: (props) => <Pre {...props} /> }}
            />
        );
    };

    /*******************************************************************
     * RENDER
     *******************************************************************/
    return (
        <div className="flex flex-col h-screen overflow-hidden mx-auto ">
            <Navbar currentNav="Add Question" />
            <header className="bg-white">
                <div className="mx-auto max-w-7xl  py-4   flex items-center bg-white"> {/* Flex container to align items */}
                    <span className="text-sm font-semibold leading-6 text-gray-600 cursor-pointer" onClick={() => navigate('/add')}>Add Question</span> {/* Text before the title */}
                    <svg className="w-4 h-4 mx-2 font-semibold fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> {/* Right chevron icon */}
                        <path fillRule="evenodd" d="M7.293 15.293a1 1 0 0 1-1.414-1.414L10.586 10 5.293 4.707a1 1 0 1 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0z" />
                    </svg>
                    <h1 className="text-sm font-semibold leading-6 text-gray-600">Generate Interview Question</h1>
                </div>
            </header>

            {/* ===== MAIN CONTENT ===== */}
            <main className="flex-grow overflow-hidden max-w-7xl mx-auto">
                <div className="w-full h-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full h-full pb-4">

                        {/* LEFT SIDE: CHAT */}
                        <div className="border flex flex-col h-full rounded-lg overflow-hidden w-full bg-gray-100 p-4 ">

                            {/* Back & Clear Buttons */}
                            <div className="flex items-center justify-between mb-4">
                                <div className="flex items-center space-x-2">
                                    {/* Back Button */}


                                    {/* Model Selector (Launches Dialog) */}
                                    <Tooltip title="Change Model">
                                        <Menu as="div" className="relative">
                                            <div>
                                                <Menu.Button
                                                    onClick={openModelDialog}
                                                    className="relative flex items-center rounded-full text-sm border border-gray-300 px-2 py-2 mr-0 rounded-lg hover:bg-gray-200"
                                                >
                                                    {notificationMethods.find((method) => method.id === modelName)?.title || 'None'
                                                    }
                                                    <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-500" />
                                                </Menu.Button>
                                            </div>
                                        </Menu>
                                    </Tooltip>

                                </div>

                                {/* Clear Button */}
                                <div
                                    className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-2"
                                    onClick={handleClearConversation}
                                >
                                    <Tooltip title="New Chat">
                                        <IconEdit className=" w-6 h-6 text-gray-500" />
                                    </Tooltip>
                                </div>
                            </div>

                            {/* Chat Messages */}
                            <div className="flex-1 overflow-auto relative" ref={messagesContainerRef}>
                                {messages.map((message, idx) => {
                                    if (message.role === 'system') return null;
                                    return (
                                        <div key={idx} className="p-2">
                                            {message.role === 'assistant' ? (
                                                <div className="flex items-start space-x-3">
                                                    <img
                                                        src={logo}
                                                        alt="Assistant"
                                                        style={{
                                                            width: 35,
                                                            height: 35,
                                                            borderRadius: '50%',
                                                            border: '1px solid #dadada',
                                                            marginTop: 2
                                                        }}
                                                    />
                                                    <div className="py-2 rounded-md bg-gray-100 flex-1">
                                                        <MarkdownViewer markdownContent={message.content} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex justify-end">
                                                    <div className="bg-gray-200 rounded-2xl px-4 p-2 max-w-lg whitespace-pre-wrap">
                                                        <MarkdownViewer markdownContent={message.content} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                {(loading || prelimLoading) && (
                                    <div className="p-4 flex items-start space-x-1">
                                        <img
                                            src={logo}
                                            alt="Assistant"
                                            style={{
                                                width: 35,
                                                height: 35,
                                                borderRadius: '50%',
                                                border: '1px solid #dadada'
                                            }}
                                        />
                                        <div className="p-2 rounded-md bg-gray-100">
                                            <span className="animate-pulse">
                                                {prelimLoading ? "Thinking" : "Generating Question"}
                                            </span>
                                        </div>
                                    </div>
                                )}

                            </div>

                            {/* Chat Input */}
                            <div className="bg-gray-100 p-2 ">
                                <div className="relative max-w-2xl mx-auto">
                                    <input
                                        type="text"
                                        value={inputText}
                                        onChange={handleInputTextChange}
                                        onKeyDown={handleKeyPress}
                                        className="w-full h-14 rounded-full pl-5 pr-10 outline-none bg-gray-200 shadow-lg focus:shadow-outline"
                                        placeholder="Describe your question..."
                                    />
                                    <button
                                        onClick={handleSendMessage}
                                        disabled={loading}
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1"
                                    >
                                        <ArrowUpCircleIcon className={`w-10 h-10 text-gray-500 ${loading ? 'opacity-50' : ''}`} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* RIGHT SIDE: PREVIEW & SAVE */}
                        <div className="relative border p-4 flex flex-col h-full overflow-hidden w-full rounded-lg">
                            {/* Header */}
                            <div className="flex justify-between items-center mb-2">
                                <div className="flex items-center">
                                    {/* AI Sparkle Icon */}
                                    {/* <SparklesIcon className="h-5 w-5 text-gray-500 mr-2" /> */}
                                    {/* Generated Question Heading */}
                                    <h2 className="text-lg font-semibold text-gray-500">Generated Question</h2>
                                </div>
                                <button
                                    onClick={handleGenSubmit}
                                    disabled={!hasLoadedGenQuestion}
                                    className="bg-green-600 text-white px-4 py-2 rounded-full text-sm font-medium shadow-lg hover:bg-green-700 transition transform hover:scale-105 flex items-center disabled:bg-green-400 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Practice
                                    <ChevronRightIcon className="h-5 w-5 ml-2" />
                                </button>


                            </div>

                            {/* Main Content */}
                            <div className="flex-1 overflow-auto p-2 border rounded-lg">
                                {hasLoadedGenQuestion && genQuestionBody ? (
                                    <div className="divide-gray-200 overflow-hidden px-2 mt-2">
                                        {/* Title and Practice Now Container */}
                                        <div className="flex items-center justify-between mb-2">
                                            <h2 className="text-2xl font-bold leading-7 text-gray-900">
                                                {genQuestionTitle}
                                            </h2>

                                        </div>
                                        {/* Tags Container */}
                                        <div className="mb-4">
                                            {genTags.map((tag, idx) => (
                                                <span
                                                    key={idx}
                                                    className="inline-flex items-center rounded-xl bg-gray-500/10 px-2 py-1 text-xs font-medium mr-1 text-gray-900"
                                                >
                                                    <IconTag className="w-3.5 h-3.5 mr-1 font-bold" />
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                        {/* Body Container */}
                                        <RenderHTMLContent content={genQuestionBody} />
                                    </div>
                                ) : errorMessage ? (
                                    <p className="text-red-500">{errorMessage}</p>
                                ) : (
                                    !startedLoadingQuestion && (
                                        <div className="flex flex-col items-center justify-center h-full text-center p-4">
                                            <SparklesIcon className="h-12 w-12 text-gray-400 mb-4" />
                                            <h3 className="text-xl font-semibold text-gray-700">Your Interview Question Awaits</h3>
                                            <p className="text-gray-500 mt-2">
                                                Describe your desired question on the left and press enter to generate a custom interview question.
                                            </p>
                                        </div>

                                    )
                                )}

                                {/* Spinner positioned in the center of the right panel */}
                                {loading && (
                                    <div className="animate-pulse space-y-4">
                                        <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                                        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                                        <div className="h-4 bg-gray-200 rounded w-full"></div>
                                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                                        <div className="h-4 bg-gray-200 rounded w-4/5"></div>
                                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                        <div className="h-4 bg-gray-200 rounded w-full"></div>
                                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                                        <div className="h-4 bg-gray-200 rounded w-4/5"></div>
                                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                                        <div className="h-4 bg-gray-200 rounded w-full"></div>
                                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                                        <div className="h-4 bg-gray-200 rounded w-full"></div>
                                    </div>
                                )}
                            </div>





                        </div>


                    </div>
                </div>

            </main>

            <Transition.Root show={isDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={closeDialog}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-20 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden h-92 rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-auto max-h-[90vh]">
                                    <div className="absolute top-0 right-0 pt-4 pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500
                                     focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={closeDialog}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Model Settings
                                    </Dialog.Title>

                                    {/* Model Selection */}
                                    <div className="mt-1 relative">
                                        <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">
                                            Select Model
                                        </label>
                                        <Menu as="div" className="relative inline-block text-left w-full">
                                            <div className="cursor-pointer hover:text-gray-900 rounded flex items-center justify-center">
                                                <Menu.Button className="inline-flex w-full justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                                                    {notificationMethods.find((method) => method.id === modelName)?.title || 'Select Model'}
                                                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-500" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-auto">
                                                    <div className="py-1">
                                                        {filteredMethods.map((method) => (
                                                            <Menu.Item key={method.id}>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => handleModelChange(method.id)}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-2 text-sm w-full text-left flex items-start justify-between'
                                                                        )}
                                                                    >
                                                                        <div>
                                                                            <div>{method.title}</div>
                                                                            <div className="text-xs text-gray-500">{method.subtitle}</div>
                                                                        </div>
                                                                        {method.id === modelName && (
                                                                            <CheckIcon aria-hidden="true" className="h-5 w-5 text-indigo-600" />
                                                                        )}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>

                                    </div>


                                    <div className="mt-8">
                                        <h4 className="text-md font-medium text-gray-900">Usage Stats</h4>
                                        <ul className="mt-2 space-y-2">
                                            {
                                                // Get the plan’s limit definitions, or an empty object if none
                                                Object.entries(planModelLimits[plan] || {}).map(([model, limit]) => {
                                                    // If usageStats doesn’t have any usage for this model, default to 0
                                                    const usageCount = usageStats[model] ?? 0;

                                                    return (
                                                        <li key={model} className="flex justify-between">
                                                            <span className="text-sm text-gray-600">{model}</span>
                                                            <span className="text-sm font-medium text-gray-900">
                                                                {usageCount} / {limit} requests
                                                            </span>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>

                                    </div>



                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>


        </div>


    );
};

export default ChatGenerateQuestion;
