import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Disclosure, Menu, Transition, Dialog } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  EyeIcon,
  EyeSlashIcon,
  UserCircleIcon,
  BookOpenIcon,
  BellIcon,
  ChevronDownIcon,
  CheckIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import { IconHelp, IconListNumbers, IconLogout, IconSettings, IconPlus } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from './config/firebase';
import { User, signOut } from 'firebase/auth';
import { db } from './config/firebase';
import { doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { planModelLimits } from './modelLimits';
import getLastYearActivity from './utils/getActivity';
import { eachDayOfInterval, format } from 'date-fns';

// Example images (adjust paths as needed)
import flamingo from './assets/_11b023d4-6379-4994-8da9-113dd5f8d912.jpeg';
import salmon from './assets/_de297e1a-070e-4710-9629-910aa2e6eac8.jpeg';
import crocodile from './assets/_1fdcdd09-4444-4eca-bc42-aa2c16ea43c6.jpeg';
import squid from './assets/_e8edb4bd-d213-4397-a17a-6a1ba6ba74c6.jpeg'

// Your logo
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'

// -- Types
interface BadgeItem {
  type: 'flamingo' | 'salmon' | 'alligator' | 'squid';
  date: string; // 'yyyy-MM-dd'
  title: string;
  description: string;
  image: string;
}

interface ActivityDay {
  addCount: number;
  submitCount: number;
}

interface BadgeDates {
  flamingo: string[];
  salmon: string[];
  alligator: string[];
  squid: string[];
}

type QuestionType = {
  qRef: string;
  title: string;
  tags: string[];
  difficulty: string;
  answerType: string;
  originalIndex: number; // Add this field
};


function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = ({ currentNav }: { currentNav: string }) => {
  // ------------------------------------
  // Original States
  // ------------------------------------
  const [modelName, setModelName] = useState<string>('gpt-4o-mini-2024-07-18');
  const [apiKey, setApiKey] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [isAnonymized, setIsAnonymized] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isActivated, setIsActivated] = useState(true);
  const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive');
  const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
  const [plan, setPlan] = useState('');
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [usageStats, setUsageStats] = useState<{ [key: string]: number }>({});
  const [unreadCount, setUnreadCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>(''); // State for search query
  const [questionSubmissions, setQuestionSubmissions] = useState<Record<string, number>>({});
  const [originalData, setOriginalData] = useState<QuestionType[]>([]);
  const [leetCodeQuestions, setLeetCodeQuestions] = useState<QuestionType[]>([]);

  // ------------------------------------
  // Badge Items (individual instances)
  // ------------------------------------
  const [badgeItems, setBadgeItems] = useState<BadgeItem[]>([]);

  // ------------------------------------
  // Track last time user viewed the badge dropdown
  // ------------------------------------
  const [lastBadgeCheck, setLastBadgeCheck] = useState<Date | null>(null);

  const searchRef = useRef<HTMLDivElement>(null);


  const navigate = useNavigate();

  // ------------------------------------
  // On mount: Auth state + fetch data
  // ------------------------------------
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);

        const userId = authUser.uid;
        const storedApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`) || '';
        // 1. Check for model in localStorage
        let storedModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

        // 2. If missing, set it to the default AND persist to localStorage
        if (!storedModelName) {
          storedModelName = 'gpt-4o-mini-2024-07-18';
          localStorage.setItem(`hinterviewGpt_modelName_${userId}`, storedModelName);
        }
        setModelName(storedModelName);
        setApiKey(storedApiKey);
        setNewApiKey(storedApiKey);
        setIsActivated(
          storedApiKey !== '' &&
          localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true'
        );

        // Load lastBadgeCheck from localStorage
        const storedCheck = localStorage.getItem(`lastBadgeCheck_${userId}`);
        if (storedCheck) {
          setLastBadgeCheck(new Date(storedCheck));
        } else {
          setLastBadgeCheck(null);
        }

        // Now fetch usage stats, subscription, badges
        await Promise.all([
          fetchUsageStats(authUser),
          fetchSubscriptions(authUser),
        ]);


      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // ------------------------------------
  // Subscription Logic
  // ------------------------------------
  const fetchSubscriptions = async (authUser: User) => {
    const subscriptionsRef = collection(db, 'customers', authUser.uid, 'subscriptions');
    const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

    const subscriptionSnap = await getDocs(activeSubscriptionsQuery);
    const subs: any[] = [];

    subscriptionSnap.forEach((doc) => subs.push(doc.data()));
    if (subs.length > 0) {
      const activeSubscription = subs[0];
      if (activeSubscription.cancel_at_period_end) {
        setCancelAtNextPeriod(true);
      }

      setSubscriptionStatus(activeSubscription.status);
      const planNickname = activeSubscription.items[0]?.price?.product?.name || 'None';
      setPlan(planNickname);
      return;
    } else {
      setPlan('None');
    }

    // Check 7-day trial
    const signUpDate = new Date(authUser.metadata.creationTime!);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - signUpDate.getTime();
    const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));
    if (daysSinceSignUp < 7) {
      setTrialRemaining(7 - daysSinceSignUp);
      setHasAccess(true);
      return;
    }

    setHasAccess(false);
    setSubscriptionStatus('Inactive');
  };

  // ------------------------------------
  // Usage Stats
  // ------------------------------------
  const fetchUsageStats = async (authUser: User) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const userId = authUser.uid;

    try {
      const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);
      const userUsageSnap = await getDoc(userUsageRef);
      if (userUsageSnap.exists()) {
        const data = userUsageSnap.data();
        setUsageStats(data.models || {});
      } else {
        setUsageStats({});
      }
    } catch (error) {
      console.error('Error fetching usage stats:', error);
    }
  };

 


  // ------------------------------------
  // Unread logic: badges after lastBadgeCheck
  // ------------------------------------
  const unreadBadgeItems = badgeItems.filter((badge) => {
    if (!lastBadgeCheck) return true;
    // console.log('lastBadgeCheck:', lastBadgeCheck, lastBadgeCheck.getTime());
    // console.log('badge.date:', badge.date, new Date(badge.date).getTime());

    return new Date(badge.date).getTime() > lastBadgeCheck.getTime();
  });
  //const unreadCount = unreadBadgeItems.length;

  // ------------------------------------
  // Handler: Click Bell Icon
  // Marks badges as read and opens the menu
  // ------------------------------------
  const handleBellClick = () => {
    if (user) {
      const now = new Date();
      setLastBadgeCheck(now);
      localStorage.setItem(`lastBadgeCheck_${user.uid}`, now.toString());

      // Save new badge counts to local storage here
      const currentBadgeCounts = badgeItems.reduce((acc, badge) => {
        acc[badge.type] = (acc[badge.type] || 0) + 1;
        return acc;
      }, {} as { [key: string]: number });

      localStorage.setItem(`badgeCounts_${user.uid}`, JSON.stringify(currentBadgeCounts));

      // Reset unread count after viewing
      setUnreadCount(0);
    }
  };

  const getLeetCodeQuestions = async () => {
    if (!user) {
      return;
    }
    if (user) {
      try {
        const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
        const userQuestionsListDoc = await getDoc(userQuestionsListRef);

        if (userQuestionsListDoc.exists()) {
          const userQuestionsList = userQuestionsListDoc.data().questions || [];

          const queriedData: QuestionType[] = userQuestionsList.map((question: QuestionType, index: number) => {
            const { title, difficulty, qRef, tags, answerType } = question;

            return {
              qRef,
              title,
              tags,
              difficulty,
              answerType,
              originalIndex: index, // Set the original index
            };
          });

          setOriginalData(queriedData);

          console.log('queriedData:', queriedData);

          const filteredData = searchQuery
            ? queriedData.filter((question) => question.title.toLowerCase().includes(searchQuery.toLowerCase()))
            : queriedData;

          setLeetCodeQuestions(filteredData);
        }
      } catch (err) {
        console.error('Error getting documents: ', err);
      }
    }
  };

  useEffect(() => {
    // Handle local changes without fetching data from Firestore
    const queriedData = [...originalData];
    const filteredData = searchQuery
      ? queriedData.filter((question) =>
        question.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      : queriedData;

    setLeetCodeQuestions(filteredData);
  }, [searchQuery, originalData]);

  useEffect(() => {
    if (user) {
      // Only call getLeetCodeQuestions if the user has been set
      getLeetCodeQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setSearchQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // ------------------------------------
  // Activate / Deactivate API Key
  // ------------------------------------
  const handleActivateApiKey = () => {
    if (user) {
      const userId = user.uid;
      localStorage.setItem(`hinterviewGpt_openAi_apiKey_${userId}`, newApiKey);
      localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'true');
      setApiKey(newApiKey);
      setIsActivated(true);
      setIsAnonymized(true);
    }
  };

  const handleDeactivateApiKey = () => {
    if (user) {
      const userId = user.uid;
      localStorage.removeItem(`hinterviewGpt_openAi_apiKey_${userId}`);
      localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'false');
      setApiKey('');
      setIsActivated(false);
      setIsAnonymized(true);
    }
  };

  const anonymizeApiKey = (key: string) => {
    return key ? '*'.repeat(key.length) : '';
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsAnonymized(true);
  };

  // ------------------------------------
  // Navigation
  // ------------------------------------
  const navigation = [
    { name: 'Overview', to: '/activity', icon: BookOpenIcon },
    { name: 'Question List', to: '/questions', icon: IconListNumbers },
    { name: 'Add Question', to: '/add', icon: IconPlus },
    // { name: 'Help', to: '/contact', icon: IconHelp },
  ];

  const logOut = async () => {
    try {
      await signOut(auth);
      navigate('/auth');
      setUser(null);
    } catch (err: any) {
      console.error(err);
    }
  };

  // Model options
  const notificationMethods = [
    {
      id: 'gpt-4o-mini-2024-07-18',
      title: 'GPT-4o mini',
      subtitle: 'Cost-efficient model based on gpt-4o-mini-2024-07-18.',
    },
    {
      id: 'gpt-4o-2024-08-06',
      title: 'GPT-4o',
      subtitle: 'Most advanced model based on gpt-4o-2024-08-06.',
    },
  ];

  const filteredMethods =
    plan === 'None'
      ? notificationMethods.filter((method) => method.id === 'gpt-4o-mini-2024-07-18')
      : notificationMethods;

  const handleModelChange = (id: string) => {
    if (user) {
      const userId = user.uid;
      setModelName(id);
      localStorage.setItem(`hinterviewGpt_modelName_${userId}`, id);
    }
  };

  return (
    <Disclosure
      as="nav"
      className="border-b z-10 relative border-b-gray-300"
      style={{ backgroundColor: '#f5f8fa' }}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl ">
            <div className="flex h-14 justify-between">
              {/* Left side */}
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-10 w-auto cursor-pointer"
                    onClick={() => navigate('/activity')}
                    src={logo}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={() => navigate(item.to)}
                      className={classNames(
                        item.name === currentNav
                          ? 'border-indigo-500 text-gray-900'
                          : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-750',
                        'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium cursor-pointer'
                      )}
                    >
                      <item.icon className="h-5 w-5 mr-2" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>

              <div className="hidden sm:flex flex-1 items-center justify-center px-4">
                {/* Wrap the search input and dropdown in a div with a ref */}
                <div ref={searchRef} className="relative w-full max-w-lg">
                  <input
                    type="text"
                    placeholder="Search questions..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full rounded-full border border-gray-300 py-2 pl-4 pr-10 focus:outline-none focus:border-indigo-500"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  {/* Dropdown for search results */}
                  {searchQuery && leetCodeQuestions.length > 0 && (
                    <div className="absolute mt-1 w-full bg-white shadow-lg rounded-md z-10 max-h-60 overflow-y-auto">
                      {leetCodeQuestions.map((question) => (
                        <div
                          key={question.qRef}
                          className="flex items-center justify-between px-4 py-2 cursor-pointer border border-gray-200 hover:bg-gray-100"
                          onClick={() => {
                            navigate(`/question/${question.qRef}`);
                            setSearchQuery("");
                          }}
                        >
                          <span className="block truncate text-bold text-gray-600">{question.title}</span>
                          <span className="ml-2 inline-block bg-gray-200 text-gray-800 text-xs px-2 py-1 rounded-full">
                            {question.answerType}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>


              </div>
              

              {/* Right side (desktop) */}
              <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-4">
              <Menu as="div" className="relative">
                  <div>
                    <Menu.Button
                      onClick={handleBellClick}
                      className="relative rounded-full p-1 text-gray-500 hover:text-gray-600 hover:bg-gray-200
                                 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2
                                 focus:ring-offset-white"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon aria-hidden="true" className="h-6 w-6" />
                      {/* Show bubble if unreadCount > 0 */}
                      {unreadCount > 0 && (
                        <span
                          className="absolute -top-1.5 -right-1.5 inline-flex items-center 
                                     justify-center px-1.5 py-0.5 text-xs font-bold leading-none 
                                     text-white bg-red-600 rounded-full"
                        >
                          {unreadCount}
                        </span>
                      )}
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      // **Key Changes: Added max-height and overflow-y-auto for scrollability**
                      className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white 
                                 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
                                 max-h-80 overflow-y-auto"
                    >
                      <div className="px-4 pb-2 text-sm font-semibold text-gray-700">
                        Notifications
                      </div>
                      {badgeItems.length === 0 ? (
                        <div className="px-4 py-2 text-sm text-gray-600">
                          No badges yet
                        </div>
                      ) : (
                        badgeItems.map((badge, idx) => (
                          <Menu.Item key={idx}>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex items-start px-4 py-2 text-sm text-gray-700 space-x-2'
                                )}
                              >
                                <img
                                  src={badge.image}
                                  alt={badge.title}
                                  className="h-6 w-6 rounded-full object-cover mt-0.5"
                                />
                                <div className="flex-1">
                                  <p className="font-medium text-gray-800">
                                    {badge.title}
                                  </p>
                                  <p className="text-xs text-gray-500">{badge.description}</p>
                                  <p className="text-xs text-gray-500 italic mt-1">
                                    Earned on {new Date(badge.date).toLocaleDateString()}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        ))
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>

                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      {user?.photoURL ? (
                        <img className="h-8 w-8 rounded-full" src={user.photoURL} alt="" />
                      ) : (
                        <UserCircleIcon className="h-8 w-8 rounded-full text-gray-500 border border-gray-300" />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white 
                               py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={() => navigate('/account')}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 flex items-center'
                            )}
                          >
                            <IconSettings className="h-5 w-5 mr-1 text-gray-500" aria-hidden="true" />
                            Account Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={logOut}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 flex items-center'
                            )}
                          >
                            <IconLogout className="h-5 w-5 mr-1 text-gray-500 ml-0.5" aria-hidden="true" />
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

              {/* Mobile menu button */}
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon aria-hidden="true" className="block h-6 w-6" />
                  ) : (
                    <Bars3Icon aria-hidden="true" className="block h-6 w-6" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile Nav */}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  onClick={() => navigate(item.to)}
                  className={classNames(
                    item.name === currentNav
                      ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                    'block border-l-4 py-2 pl-3 pr-4 text-base font-medium cursor-pointer'
                  )}
                  aria-current={item.name === currentNav ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {user?.photoURL ? (
                    <img className="h-10 w-10 rounded-full" src={user.photoURL} alt="" />
                  ) : (
                    <UserCircleIcon className="h-10 w-10 rounded-full text-gray-500" />
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user?.displayName || 'User'}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user?.email || 'user@example.com'}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Your Profile
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Settings
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  onClick={logOut}
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>

          {/* Dialog: Model Settings / API Key */}
          <Transition.Root show={isDialogOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={closeDialog}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-20 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden h-92 rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-auto max-h-[90vh]">
                      <div className="absolute top-0 right-0 pt-4 pr-4">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={closeDialog}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Model Settings
                      </Dialog.Title>

                      {/* Model Selection */}
                      <div className="mt-1 relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">
                          Select Model
                        </label>
                        <Menu as="div" className="relative inline-block text-left w-full">
                          <div className="cursor-pointer hover:text-gray-900 rounded flex items-center justify-center">
                            <Menu.Button className="inline-flex w-full justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                              {notificationMethods.find((method) => method.id === modelName)?.title || 'Select Model'}
                              <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-500" />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-auto">
                              <div className="py-1">
                                {filteredMethods.map((method) => (
                                  <Menu.Item key={method.id}>
                                    {({ active }) => (
                                      <button
                                        onClick={() => handleModelChange(method.id)}
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm w-full text-left flex items-start justify-between'
                                        )}
                                      >
                                        <div>
                                          <div>{method.title}</div>
                                          <div className="text-xs text-gray-500">{method.subtitle}</div>
                                        </div>
                                        {method.id === modelName && (
                                          <CheckIcon aria-hidden="true" className="h-5 w-5 text-indigo-600" />
                                        )}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        {!['Advanced', 'Standard', 'None'].includes(plan) && (
                          <a
                            href="https://openai.com/api/pricing/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block text-sm text-indigo-600 hover:underline mt-2"
                          >
                            OpenAI model pricing
                          </a>
                        )}
                      </div>

                      {/* Usage stats vs. OpenAI API Key */}
                      {['Advanced', 'Standard', 'None'].includes(plan) ? (
                        <div className="mt-8">
                          <h4 className="text-md font-medium text-gray-900">Usage Stats</h4>
                          <ul className="mt-2 space-y-2">
                            {
                              // Get the plan’s limit definitions, or an empty object if none
                              Object.entries(planModelLimits[plan] || {}).map(([model, limit]) => {
                                // If usageStats doesn’t have any usage for this model, default to 0
                                const usageCount = usageStats[model] ?? 0;

                                return (
                                  <li key={model} className="flex justify-between">
                                    <span className="text-sm text-gray-600">{model}</span>
                                    <span className="text-sm font-medium text-gray-900">
                                      {usageCount} / {limit} requests
                                    </span>
                                  </li>
                                );
                              })
                            }
                          </ul>

                        </div>
                      ) : (
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">
                            OpenAI API Key
                          </label>
                          <div className="flex items-center space-x-2">
                            <Tooltip
                              title={isActivated ? 'To edit API key, you must deactivate it.' : ''}
                              arrow
                            >
                              <span className="w-full">
                                <input
                                  type="text"
                                  className={classNames(
                                    'block w-full px-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10',
                                    isActivated ? 'bg-gray-100' : ''
                                  )}
                                  value={isAnonymized && newApiKey ? anonymizeApiKey(newApiKey) : newApiKey}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (isAnonymized && value.length > newApiKey.length) {
                                      // Typed a new character while anonymized
                                      setNewApiKey(value.slice(-1));
                                    } else if (!isAnonymized) {
                                      setNewApiKey(value);
                                    } else {
                                      setNewApiKey('');
                                    }
                                  }}
                                  onFocus={() => setIsAnonymized(false)}
                                  disabled={isActivated}
                                />
                              </span>
                            </Tooltip>
                            <button
                              type="button"
                              className="flex items-center justify-center p-0 rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-10 w-10"
                              onClick={() => setIsAnonymized(!isAnonymized)}
                            >
                              {isAnonymized ? <EyeIcon className="h-5 w-5" /> : <EyeSlashIcon className="h-5 w-5" />}
                            </button>
                          </div>
                          <a
                            href="https://platform.openai.com/api-keys"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block text-sm text-indigo-600 hover:underline mt-2"
                          >
                            How to get an OpenAI API key
                          </a>
                        </div>
                      )}

                      {/* Activate/Deactivate if plan not Advanced/Standard/None */}
                      {!['Advanced', 'Standard', 'None'].includes(plan) && (
                        <div className="mt-2 sm:mt-2 sm:flex sm:flex-row-reverse">
                          {isActivated ? (
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={handleDeactivateApiKey}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={handleActivateApiKey}
                            >
                              Activate
                            </button>
                          )}
                        </div>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;